<template>
  <GlobalWrapper
    :style="{ backgroundImage: 'url(' + campaign?.background + ')' }"
    class="py-20 bg-cover min-h-screen"
  >
    <div :class="loading || !campaignActive ? 'disabled' : null + 'mx-auto'">
      <div class="flex justify-between">
        <img :src="npo?.logo" alt="Non-profit logo" class="max-w-[11rem]" />

        <div class="flex flex-col space-y-2" v-if="userId">
          <a
            href=""
            @click.prevent="toggleQRCodeModal"
            class="text-center rounded bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
            :class="loading || !campaignActive ? 'disabled' : null"
            >Share QR Code</a
          >
          <router-link
            :to="{ name: 'CampaignList' }"
            class="text-center rounded bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
            :class="loading || !campaignActive ? 'disabled' : null"
            >View Dashboard</router-link
          >
        </div>
      </div>

      <Spinner v-show="loading" class="mt-10" />
      <div v-show="!loading" class="relative mt-4">
        <!-- action form -->

        <!-- Step 1: Personal Details -->
        <div v-show="stepNumber === 0">
          <DonorFormPersonal
            @personalData="setPersonalData"
            @stepNumber="getStepNumber"
            @userAge="setUserAge"
            :lowestAge="lowestAge"
          />
        </div>

        <!-- Step 2: Contact Details  -->
        <div v-show="stepNumber === 1">
          <template
            v-if="
              paymentProviders[paymentProvider] !== EveryAction &&
              paymentProviders[paymentProvider] !== SpringBoard
            "
          >
            <DonorFormContact @contactData="getContactData" @stepNumber="getStepNumber" />
          </template>
          <template v-else>
            <div class="flex flex-column">
              <div class="flex items-start">
                <input
                  id="terms"
                  text="terms"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  v-model="termsAgreement.value"
                  required
                />
              </div>
              <div class="leading-6 py-4 px-4 bg-white rounded-sm">
                <label
                  v-if="paymentProviders[paymentProvider] === SpringBoard"
                  for="terms"
                  class="text-xs inline-block"
                >
                  I am at least 25 years old and I am agreeing to contribute financial resources to
                  International Rescue Committee of my own free will. I understand that this is an
                  ongoing gift, vital to the International Rescue Committee’s mission to respond to
                  the world’s worst humanitarian crises and help people to survive and rebuild their
                  lives, and my gift will continue until I notify International Rescue Committee of
                  any change.
                  <br />
                  <br />
                  I understand that I have spoken to a paid fundraiser employed by an agency paid a
                  fee and authorized by International Rescue Committee to conduct this type of
                  face-to-face fundraising work. I authorize the selected amount to be paid to
                  International Rescue Committee from my credit/bank as indicated above. I agree to
                  allow the International Rescue Committee and their authorized partner agency to
                  contact me by telephone, text, email, or mail regarding my donation. I give
                  consent to International Rescue Committee ownership rights to my photo or image,
                  to reproduce and use for any purpose worldwide in furtherance of International
                  Rescue Committee’s mission without exception of compensation to me now or in the
                  future. If, for any reason, I decide to cancel my contribution, I may do so at any
                  time by calling International Rescue Committee at 1-855-973-7283. A record of my
                  payment(s) will appear on my credit card or bank statement and will serve as a
                  receipt.
                </label>
                <label v-else for="terms" class="text-xs inline-block">
                  I understand that I have spoken to a paid fundraiser employed by New Canvassing
                  Experience, Inc., an agency paid a fee by {{ npo?.name }} to conduct this
                  face-to-face fundraising campaign. I authorize the agreed upon amount to be
                  debited from my bank account or charged to my credit card account, and paid to
                  {{ npo?.name }} immediately, and authorize subsequent payments to be processed at
                  the agreed upon frequency following initial payment, until further notice from me
                  to {{ npo?.name }}. If, for any reason, I need to update or suspend my
                  contributions, I may do so at any time by contacting {{ npo?.name }}

                  <br />
                  <br />
                  Note: New Canvassing Experience Inc. is not permitted to take cash or any other
                  form of donatino other than credit/debit card or direct debit contributions on
                  behalf of CeaseFire PA.
                  <br />
                  <br />
                  Contributions or gifts to CeaseFire PA are not tax deductible as charitable
                  contributions or as business expenses under IRC Section 162(e).
                </label>
              </div>
            </div>
            <button
              class="btn--primary w-full"
              :disabled="!termsAgreement.value"
              @click="getStepNumber(paymentProviders[paymentProvider] === SpringBoard ? 1.5 : 2)"
            >
              Accept Terms
            </button>
            <button
              class="w-full mt-2 py-2 px-4 border-2 text-black-500 bg-transparent rounded-md hover:bg-gray-100 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed"
              @click="getStepNumber(0)"
            >
              Go Back
            </button>
          </template>
        </div>

        <!-- step 2.5 Contact Details -->
        <div v-show="stepNumber === 1.5">
          <DonorFormContact @contactData="getContactData" @stepNumber="getStepNumber" />
        </div>

        <!-- Step 3: Payment Details  -->
        <div v-show="stepNumber === 2">
          <DonorFormPayment
            v-if="paymentProviders[paymentProvider] !== EveryAction"
            @submitForm="onSubmitForms"
            @stepNumber="getStepNumber"
            :campaign-api-url="campaignApiUrl"
            :campaign-id="campaignId"
            :npoName="npo?.name"
            :frequency-options="filteredFrequencies"
            :emailOptIn="emailOptIn"
          />

          <div class="flex justify-center" v-else>
            <div ref="everyActionContainer"></div>
            <!-- <button @click="getEaFormData">Get Data</button> -->
          </div>
        </div>
      </div>
    </div>
    <Modal
      headerText="Thank you for your donation!"
      ctaText="Close and Reset Form"
      :subheaderText="donateSuccessMessage"
      :modalOpen="successModalOpen"
      @closeModal="toggleSuccessModal"
      :showSuccessIcon="true"
    />
    <Modal
      headerText="Donation Failed :("
      ctaText="Close and Try Again"
      :subheaderText="'Reason: ' + donateFailureMessage"
      :modalOpen="failureModalOpen"
      @closeModal="toggleFailureModal"
      :showFailureIcon="true"
    />
    <Modal
      headerText="Scan the QR code to donate!"
      ctaText="Close"
      :modalOpen="qrCodeModalOpen"
      @closeModal="toggleQRCodeModal"
      :modalImgUrl="qrCodeImgUrl"
    />
    <Toast v-if="loading" text="Loading..." />
    <Toast v-else-if="!loading && !campaignActive" :text="disabledMessage" />
  </GlobalWrapper>
</template>

<script>
import { ref, onBeforeMount, computed, watchEffect, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import GlobalWrapper from '../components/GlobalWrapper'
import DonorFormPersonal from '@/components/DonorFormPersonal'
import DonorFormContact from '@/components/DonorFormContact'
import DonorFormPayment from '@/components/DonorFormPayment'
import Modal from '@/components/Modal'
import Toast from '@/components/Toast'
import Spinner from '@/components/Spinner'
import { CampaignApi } from '@/services/campaign/'
import { everyAction } from '@/composables/everyAction'
import { EveryAction, SpringBoard, paymentProviders } from '@/services/npo'
import { donationActions } from '@/composables/donationSubmissions'
import { useRoute } from 'vue-router'
import { sample } from '@/services/frequencies/sample'
import { useRouter } from 'vue-router'
import * as Sentry from '@sentry/vue'

export default {
  name: 'CampaignDonate',
  components: {
    GlobalWrapper,
    DonorFormPersonal,
    DonorFormContact,
    DonorFormPayment,
    Modal,
    Toast,
    Spinner,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const donateFailureMessage = ref('')

    const {
      campaign,
      onSubmitForms,
      onSubmitFormsAlt,
      campaignId,
      fundraiserId,
      loading,
      donorId,
      getMaskedPan,
      toggleFailureModal,
      toggleSuccessModal,
      successModalOpen,
      failureModalOpen,
      donateSuccessMessage,
      createDonor,
      termsAgreement,
    } = donationActions()
    const userToken = store.getters.token
    const userId = store.getters.user ? store.getters.user.id : ''
    const isFundraiser = store.getters.isFundraiser
    const npo = ref(null)
    const age = ref(18)
    const stepNumber = ref(0)
    const acceptedTerms = ref(true)

    const qrCodeModalOpen = ref(false)
    const qrCodeImgUrl = ref('')
    const campaignQueryParams = fundraiserId ? `?fundraiser=${fundraiserId}` : ''

    const campaignName = ref(null)
    const emailOptIn = ref(false)
    const campaignApiUrl = `/campaigns/${campaignId}/${campaignQueryParams}`

    const filteredFrequencies = computed(() =>
      campaign.value?.frequencies.filter((frequency) => frequency.minAge <= age.value),
    )
    const lowestAge = computed(() => {
      if (campaign.value) {
        const ages = campaign.value.frequencies.map((frequency) => frequency.minAge)
        return Math.min(...ages)
      }
      return age.value
    })
    const setUserAge = function (newAge) {
      age.value = newAge
    }
    const {
      paymentProvider,
      everyActionContainer,
      everyActionProvider,
      calledPostOrPre,
      getEaFormData,
    } = everyAction()

    onBeforeMount(async () => {
      loading.value = true
      try {
        campaign.value = await CampaignApi.csc.customRetrieve({
          campaign: campaignId,
          fundraiser: fundraiserId,
        })
        npo.value = campaign.value.npo
        campaignName.value = campaign.value.name
        /**
         * TODO: Here we will check the payment processor
         */
        paymentProvider.value = campaign.value.latestPaymentProvider?.paymentProvider
      } catch (e) {
        console.log(e)
      } finally {
        loading.value = false
      }
    })
    const getStepNumber = (data) => {
      stepNumber.value = data
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }

    const getQRCode = () => {
      const url = `${location.host}${route.fullPath}?fundraiser=${encodeURIComponent(
        userId,
      )}&from_qrcode=true`
      qrCodeImgUrl.value = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
        url,
      )}&size=150x150`
    }

    const toggleQRCodeModal = () => {
      getQRCode()
      qrCodeModalOpen.value = !qrCodeModalOpen.value
    }

    const campaignActive = computed(() => {
      return (
        campaign.value?.active &&
        campaign.value?.latestPaymentProvider &&
        campaign.value?.collectingDonations
      )
    })

    const disabledMessage = computed(() => {
      let reasons = []
      let message = ''

      if (!campaign.value) {
        if (isFundraiser) {
          message =
            'You do not have access to this campaign. Please contact your organization admin for further assistance.'
        } else {
          message =
            'There was an error retrieving the campaign details. Please refresh the page or contact an admin for further assistance.'
        }
      } else {
        message = 'This campaign is inactive.'

        !campaign.value?.collectingDonations &&
          reasons.push(" It's marked as 'Not Accepting Donations'.")

        !campaign.value?.latestPaymentProvider &&
          reasons.push(" There's no payment provider attached.")

        if (reasons.length > 1) {
          message += reasons.join(' and')
        } else if (reasons.length === 1) {
          message += reasons[0]
        }
      }
      return message
    })

    async function setPersonalData(data) {
      // debugger
      localStorage.setItem('DonorData', JSON.stringify(data))

      localStorage.setItem('fundraiserId', fundraiserId ?? userId ?? null)
      localStorage.setItem('campaign', JSON.stringify(campaign.value) ?? null)
      const from_qrcode = route.query.from_qrcode !== undefined
      localStorage.setItem('qrCode', JSON.stringify(from_qrcode))
      Sentry.captureMessage(JSON.stringify({ data: data, function: 'setPersonalData' }))
      /**
       * HACK - You can use this to shortcut the form and submit manual data
       */
      // localStorage.setItem('submissionData', JSON.stringify(sample))
      // router.push({ name: 'ThankYou' })
      // await onSubmitFormsAlt(sample, fundraiserId ?? userId ?? null)
    }
    function getContactData(data) {
      const currentData = JSON.parse(localStorage.getItem('DonorData'))
      const donor = {
        ...currentData,
        ...data,
      }

      emailOptIn.value = donor.isEmailPreferred
      localStorage.setItem('DonorData', JSON.stringify({ donor }))
    }

    onUnmounted(() => {
      console.log('Unmounted')
      // Sentry.captureMessage('Unmounted, checking call of post or pre', calledPostOrPre.value)
      everyActionProvider.removeFormAndScripts()
    })

    return {
      setPersonalData,
      age,
      setUserAge,
      lowestAge,
      filteredFrequencies,
      userToken,
      userId,
      campaign,
      npo,
      DonorFormPersonal,
      DonorFormContact,
      DonorFormPayment,
      stepNumber,
      successModalOpen,
      failureModalOpen,
      qrCodeModalOpen,
      qrCodeImgUrl,
      campaignApiUrl,
      campaignId,
      onSubmitForms,
      toggleQRCodeModal,
      toggleSuccessModal,
      toggleFailureModal,
      donateFailureMessage,
      donateSuccessMessage,
      getStepNumber,
      getQRCode,
      campaignActive,
      loading,
      disabledMessage,
      campaignName,
      emailOptIn,
      getMaskedPan,
      everyActionContainer,
      paymentProvider,
      getContactData,
      EveryAction,
      SpringBoard,
      paymentProviders,
      onSubmitFormsAlt,
      acceptedTerms,
      termsAgreement,
      getEaFormData,
    }
  },
}
</script>
