<template>
  <!-- Custom questions -->
  <div class="col-span-full col-start-1 border-t border-gray-900/10 pt-6 mt-6">
    <div class="space-y-6">
      <fieldset v-for="(question, index) in customQuestions" :key="question.id" class="space-y-4">
        <legend class="input--label">{{ question.text }}</legend>
        <div
          v-for="answer in question.answers"
          :key="answer.id"
          class="flex items-center custom-question-fieldset"
        >
          <input
            :id="answer.id"
            type="radio"
            :name="question.id"
            class="h-6 w-6 border-gray-300 text-primary focus:ring-primary"
            @input="getQuestionData(question, answer, index)"
          />
          <label :for="answer.id" class="ml-3 block text-md font-normal leading-6 text-gray-900">{{
            answer.text
          }}</label>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/AxiosClient.js'
import { ref } from 'vue'

export default {
  name: 'DonorFormCustomQuestions',
  emits: ['questionData'],
  props: ['campaignApiUrl'],
  setup(_, { emit }) {
    const customQuestions = ref([])
    const questionData = ref([])

    return {
      customQuestions,
      questionData,
    }
  },
  mounted() {
    ApiService.get(this.campaignApiUrl).then((response) => {
      this.customQuestions = response.data.questions
    })
  },
  methods: {
    getQuestionData(question, answer, index) {
      // Get the index of the currently selected question in the questionData array, if it exists in the array.
      const objIndex = this.questionData.findIndex((obj) => obj.question.text === question.text)

      // Check to see if this question has already been answered. If it has, overwrite the answer. If it hasn't, push new data to the object.
      if (objIndex !== index) {
        this.questionData.push({
          option: answer.id,
          question: {
            text: question.text,
            apiValue: question.api_value,
            answers: [answer],
          },
        })
      } else {
        this.questionData[objIndex].option = answer.id
        this.questionData[objIndex].question.text = question.text
        this.questionData[objIndex].question.answers = [answer]
        this.questionData[objIndex].question.apiValue = [question.api_value]
      }

      this.$emit('questionData', this.questionData)
    },
  },
}
</script>
