<template>
  <form
    class="space-y-6 px-4 py-8 sm:px-8 border border-gray-300 rounded-md bg-white"
    method="POST"
    target="_blank"
    @submit.prevent="submitPersonalDataForm"
  >
    <div>
      <h2 class="text-xl font-semibold leading-3 text-gray-900">Personal Details</h2>
    </div>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
      <!-- First & Last name -->
      <div class="sm:col-span-3">
        <InputField
          v-model:value="form.firstName.value"
          :errors="form.firstName.errors"
          @blur="form.firstName.validate()"
          label="First name"
          type="text"
          text="first-name"
          id="first-name"
          placeholder=""
          autocomplete="given-name"
        />
      </div>
      <div class="sm:col-span-3">
        <InputField
          v-model:value="form.lastName.value"
          :errors="form.lastName.errors"
          @blur="form.lastName.validate()"
          label="Last name"
          type="text"
          text="last-name"
          id="last-name"
          placeholder=""
          autocomplete="family-name"
        />
      </div>
    </div>

    <!-- DOB -->
    <div class="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
      <label class="input--label col-span-full grid-">Date of Birth</label>
      <div class="col-span-3 mb-4 sm:mb-0">
        <SelectField
          :selectOptions="this.monthData"
          v-model:value="form.birthMonth.value"
          :errors="form.birthMonth.errors"
          :hideLabel="true"
          label="Month"
        />
      </div>
      <div class="col-span-1">
        <InputField
          v-model:value="form.birthDay.value"
          :errors="form.birthDay.errors"
          @blur="form.birthDay.validate()"
          type="number"
          text="birth-day"
          id="birth-day"
          placeholder="01"
        />
      </div>
      <div class="col-span-2">
        <SelectField
          :selectOptions="this.years"
          v-model:value="form.birthYear.value"
          :errors="form.birthYear.errors"
          :hideLabel="true"
          label="Year"
        />
      </div>
    </div>

    <div class="flex w-full ml-auto space-x-6">
      <button type="submit" class="btn--primary w-full" id="personal-data-submit">Next Step</button>
    </div>
  </form>
  <Modal
    :headerText="`You need to be at least ${lowestAge} years old to donate.`"
    ctaText="Sorry about that!"
    :modalOpen="this.tooYoungModalOpen"
    @closeModal="toggleTooYoungModal"
    :showFailureIcon="true"
  />
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import SelectField from '@/components/inputs/SelectField'
import InputField from '@/components/inputs/InputField'
import { PersonalDonorForm } from '@/services/users/'
import monthData from '@/data/monthData.js'
import Modal from '@/components/Modal'

export default {
  name: 'DonorFormPersonal',
  components: {
    SelectField,
    InputField,
    Modal,
  },
  emits: ['personalData', 'stepNumber', 'userAge'],
  props: ['lowestAge'],
  setup(props, { emit }) {
    const form = ref(new PersonalDonorForm())
    const submitButton = ref(null)
    const currentYear = new Date().getFullYear()
    const years = computed(() => {
      return Array.from({ length: currentYear - 1900 }, (value, index) => {
        const year = 1901 + index
        return { value: year, text: `${year}` }
      }).reverse()
    })
    const birthYear = ref(currentYear)
    const tooYoungModalOpen = ref(false)
    const donorLat = ref(null)
    const donorLong = ref(null)

    onMounted(() => {
      submitButton.value = document.querySelector('#personal-data-submit')
      getLocation()
    })

    //This function gets the user's geolocation and returns the lat/long
    function getLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          form.value.latitude.value = position.coords.latitude
          form.value.longitude.value = position.coords.longitude
        })
      } else {
        console.log('Geolocation not available by this browser.')
      }
    }

    function submitPersonalDataForm() {
      const unwrappedForm = form.value
      unwrappedForm.validate()
      if (!unwrappedForm.isValid) return

      if (handleAgeCheck() === false) {
        toggleTooYoungModal()
        return
      }

      // Emit the data to the parent component
      emit('personalData', form.value.formVal)
      emit('stepNumber', 1)
      emit('userAge', calculateAge())
    }

    function toggleTooYoungModal() {
      tooYoungModalOpen.value = !tooYoungModalOpen.value
    }

    function calculateAge() {
      const unwrappedForm = form.value
      const today = new Date()
      const birthDate = new Date(
        unwrappedForm.birthYear.value,
        unwrappedForm.birthMonth.value,
        unwrappedForm.birthDay.value,
      )

      let age = today.getFullYear() - birthDate.getFullYear()
      const m = (today.getMonth() + 1) - birthDate.getMonth() // prettier-ignore

      // If the birth month is after the current month, or if we're in the birthday month and the bday hasn't happened yet, subtract 1 from age
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }

      return age
    }

    function handleAgeCheck() {
      if (calculateAge() < props.lowestAge) {
        return false
      }
    }

    return {
      form,
      submitButton,
      birthYear,
      monthData,
      years,
      tooYoungModalOpen,
      submitPersonalDataForm,
      toggleTooYoungModal,
      donorLat,
      donorLong,
    }
  },
}
</script>
