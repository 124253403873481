<template>
  <form
    class="space-y-6 px-4 py-8 sm:px-8 border border-gray-300 rounded-md bg-white"
    method="POST"
    target="_blank"
    @submit.prevent="submitContactDataForm"
  >
    <div>
      <h2 class="text-xl font-semibold leading-3 text-gray-900">Contact Details</h2>
    </div>

    <fieldset class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
      <div class="sm:col-span-4 col-span-1">
        <InputField
          v-model:value="form.addressStreet.value"
          :errors="form.addressStreet.errors"
          @blur="form.addressStreet.validate()"
          label="Street address"
          type="text"
          text="street-address"
          id="street-address"
          placeholder=""
        />
      </div>
      <div class="sm:col-span-2 col-span-1">
        <InputField
          v-model:value="form.addressDetail.value"
          :errors="form.addressDetail.errors"
          @blur="form.addressDetail.validate()"
          label="Apt, Floor, or Suite"
          type="text"
          text="address-detail"
          id="address-detail"
          placeholder=""
        />
      </div>

      <div class="sm:col-span-2 sm:col-start-1">
        <InputField
          v-model:value="form.city.value"
          :errors="form.city.errors"
          @blur="form.city.validate()"
          label="City"
          type="text"
          text="city"
          id="city"
          placeholder=""
          autocomplete="address-level2"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-3">
        <SelectField
          :selectOptions="this.usStateData"
          v-model:value="form.state.value"
          label="State"
          :errors="form.state.errors"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-5">
        <InputField
          v-model:value="form.postalCode.value"
          :errors="form.postalCode.errors"
          @blur="form.postalCode.validate()"
          label="Zip / Postal code"
          type="text"
          text="postal-code"
          id="postal-code"
          placeholder=""
          autocomplete="postal-code"
        />
      </div>
    </fieldset>

    <div class="grid grid-cols-1 sm:grid-cols-6 gap-y-8 gap-x-6 border-t border-gray-900/10 pt-6">
      <div class="col-span-4">
        <InputField
          v-model:value="form.email.value"
          :errors="form.email.errors"
          @blur="form.email.validate()"
          label="Email"
          type="email"
          text="email"
          id="email"
          placeholder="example@email.com"
          autocomplete="email"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-6">
      <div class="sm:col-span-2">
        <InputField
          v-model:value="form.mobilePhone.value"
          :errors="form.mobilePhone.errors"
          @blur="form.mobilePhone.validate()"
          label="Cell phone"
          type="text"
          text="mobile-phone"
          id="mobile-phone"
          placeholder="XXX-XXX-XXXX"
          autocomplete="phone"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          title="Phone number should be in the format XXX-XXX-XXXX"
        />
      </div>
      <div class="sm:col-span-2 sm:col-start-3">
        <InputField
          v-model:value="form.homePhone.value"
          :errors="form.homePhone.errors"
          label="Home phone"
          type="text"
          text="home-phone"
          id="home-phone"
          placeholder="XXX-XXX-XXXX"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          title="Phone number should be in the format XXX-XXX-XXXX"
        />
      </div>
    </div>
    <fieldset class="grid grid-cols-1 sm:grid-cols-full mt-2">
      <label class="input--label">Preferred communication method</label>

      <div class="space-x-2 sm:space-x-6 flex">
        {{ form.isSmsPreferred.placeholder }}
        <div
          v-for="(option, i) in [
            form.isEmailPreferred,
            form.isSmsPreferred,
            form.isPhoneCallPreferred,
          ]"
          :key="i"
          class="items-center justify-center flex-1"
        >
          <input
            :id="option.label"
            type="checkbox"
            class="hidden peer"
            :value="true"
            v-model="option.value"
          />
          <label
            :for="option.label"
            class="block text-lg leading-6 font-medium text-gray-900 rounded-md border border-gray-300 py-3 px-3 text-center peer-checked:bg-gray-500 peer-checked:text-white"
            >{{ option.label }}</label
          >
        </div>
      </div>
    </fieldset>

    <div class="flex w-full space-x-2 sm:space-x-6">
      <button @click="prevStep" type="button" class="btn--secondary w-full">Back</button>
      <button type="submit" class="btn--primary w-full">Save &amp; Continue</button>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import SelectField from '@/components/inputs/SelectField'
import InputField from '@/components/inputs/InputField'
import { ContactDonorForm } from '@/services/users/'
import usStateData from '@/data/usStateData.js'

export default {
  name: 'DonorFormContact',
  components: {
    SelectField,
    InputField,
  },
  emits: ['contactData', 'stepNumber'],
  setup(_, { emit }) {
    const form = ref(new ContactDonorForm())

    function submitContactDataForm() {
      const unwrappedForm = form.value
      unwrappedForm.validate()
      if (!unwrappedForm.isValid) return

      // Emit the data to the parent component
      emit('contactData', unwrappedForm.value)
      emit('stepNumber', 2)
    }

    function prevStep() {
      emit('stepNumber', 0)
    }

    return {
      form,
      submitContactDataForm,
      usStateData,
      prevStep,
    }
  },
}
</script>
