import { watchEffect, ref, inject, computed } from 'vue'
import options, { MONTHLY, YEARLY, ONETIME } from '@/services/frequencies/options'
import { EveryAction, paymentProviders } from '../services/npo/models'
import { donationActions } from './donationSubmissions'
import { useRouter } from 'vue-router'
import * as Sentry from '@sentry/vue'

export function everyAction() {
  /**
   * we need to grab the every action form elements and populate them whenever the form is populated
   */

  const $everyaction = inject('$everyaction')
  const everyActionContainer = ref(null)
  const everyActionLoaded = ref(false)
  const paymentProvider = ref(null)
  const kickoffSubmission = ref(null)
  const externalTransactionData = ref(null)
  const altFormSaveInProgress = ref(false)
  const eaContactForm = ref(null)
  const eaContributionForm = ref(null)
  const eaPaymentForm = ref(null)
  const router = useRouter()
  const calledPostOrPre = ref(false)
  const { fundraiserId } = donationActions()

  $everyaction.addPostRenderCallback((args) => {
    eaContributionForm.value = document.querySelector('.ContributionInformation')
    eaPaymentForm.value = document.querySelector('.PaymentInformation')
    eaContactForm.value = document.querySelector('.ContactInformation')
  })

  $everyaction.addOnSubmitCallback((args) => {
    let formData = getEaFormData()

    Sentry.captureMessage(
      JSON.stringify({
        data: localStorage.getItem('DonorData'),
        ea: formData,
        function: 'everyAction:addOnSubmitCallback',
        fundraiserId: localStorage.getItem('fundraiserId'),
      }),
    )
  })

  $everyaction.addPreSegueCallback((args) => {
    /**
     * get the appropriate donation info and save it to the db
     *
     */
    calledPostOrPre.value = true
    localStorage.setItem('submissionData', JSON.stringify(args ?? {}))
    router.push({ name: 'ThankYou' })
    window.location.href = '/thank-you'
  })

  $everyaction.addSegueCallback((args) => {
    // adding this just in case, but really it should never get here !
    if (args?.thank) {
      calledPostOrPre.value = true
      router.push({ name: 'ThankYou' })
      window.location.href = '/thank-you'
    }
  })

  watchEffect(() => {
    if (
      everyActionContainer.value &&
      !everyActionContainer.value.children.length &&
      paymentProvider.value &&
      paymentProviders[paymentProvider.value] === EveryAction
    ) {
      /**
       * TODO: Only set this up if the current id is correct
       */
      $everyaction.showForm('IZxl0vt4SkmY_ZlB9D6VCQ2', everyActionContainer.value)
    }
    if (eaContributionForm.value && eaContactForm.value && eaPaymentForm.value) {
      everyActionLoaded.value = true
    }
  })

  function fillForm(data) {
    console.log('filling form')
    eaContactForm.value.querySelector("[name='FirstName']").value = data['billing.name.first']
    eaContactForm.value.querySelector("[name='LastName']").value = data['billing.name.last']
    eaContactForm.value.querySelector("[name='AddressLine1']").value =
      data['billing.address.street1']
    eaContactForm.value.querySelector("[name='City']").value = data['billing.address.city']
    eaContactForm.value.querySelector("[name='StateProvince']").value =
      data['billing.address.state']
    eaContactForm.value.querySelector("[name='PostalCode']").value = data['billing.address.zip']
    // eaContactForm.value.querySelector("[name='MobilePhone']").value = data['billing.phoneN
    eaContactForm.value.querySelector("[name='EmailAddress']").value = data['donor.email']
    eaContributionForm.value.querySelector("[name='OtherAmount']").value = data['otherAmount']

    const selectedFrequency = [MONTHLY, YEARLY, ONETIME].find(
      (option) => option === data['sustaining.frequency'],
    )

    const frequOptions = eaContributionForm.value.querySelectorAll("[name='SelectedFrequency']")
    Array.from(frequOptions).forEach((option) => {
      if (option.value == '4' && selectedFrequency === MONTHLY) {
        option.checked = true
      } else if (option.value == '6' && selectedFrequency === YEARLY) {
        option.checked = true
      } else if (option.value == '0' && selectedFrequency === ONETIME) {
        option.checked = true
      }
    })
  }

  function getEaFormData() {
    const firstName = eaContactForm.value.querySelector("[name='FirstName']")?.value
    const lastName = eaContactForm.value.querySelector("[name='LastName']")?.value
    const address = eaContactForm.value.querySelector("[name='AddressLine1']")?.value
    const city = eaContactForm.value.querySelector("[name='City']")?.value
    const state = eaContactForm.value.querySelector("[name='StateProvince']")?.value
    const postalCode = eaContactForm.value.querySelector("[name='PostalCode']")?.value
    const email = eaContactForm.value.querySelector("[name='EmailAddress']")?.value
    const amount = eaContributionForm.value.querySelector("[name='OtherAmount']")?.value
    // const frequency = eaContributionForm.value.querySelector("[name='SelectedFrequency']")?.value
    const mobilePhone = eaContactForm.value.querySelector("[name='MobilePhone']")?.value
    const countryCode = document.querySelector('.iti__selected-flag')?.title?.replace(/\D/g, '')

    const data = {
      firstName,
      lastName,
      address,
      city,
      state,
      postalCode,
      email,
      amount,
      // frequency,
      mobilePhone,
      countryCode,
    }
    return data
  }

  return {
    everyActionContainer,
    everyActionLoaded,
    eaContactForm,
    eaContributionForm,
    eaPaymentForm,
    fillForm,
    paymentProvider,
    kickoffSubmission,
    externalTransactionData,
    altFormSaveInProgress,
    everyActionProvider: $everyaction,
    getEaFormData,
  }
}
